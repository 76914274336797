@use '../@tokens' as tokens;
@import '~@lmc-eu/cookie-consent-manager/LmcCookieConsentManager.css';

:root {
  --lmcccm-link-text: #{tokens.$action-link-primary-default};
  --lmcccm-link-hover-text: #{tokens.$action-link-primary-hover};
  --lmcccm-p-btn-primary-bg: #{tokens.$action-primary-default};
  --lmcccm-p-btn-primary-border: #{tokens.$action-primary-default};
  --lmcccm-p-btn-primary-hover-bg: #{tokens.$action-primary-hover};
  --lmcccm-p-btn-primary-hover-border: #{tokens.$action-primary-hover};
  --lmcccm-p-btn-primary-active-bg: #{tokens.$action-primary-hover};
  --lmcccm-p-btn-primary-active-border: #{tokens.$action-primary-hover};
  --lmcccm-p-btn-secondary-text: #{tokens.$action-primary-default};
  --lmcccm-p-btn-secondary-border: #{tokens.$background-basic};
  --lmcccm-p-btn-secondary-hover-text: #{tokens.$action-primary-hover};
  --lmcccm-p-btn-secondary-hover-border: #{tokens.$background-basic};
  --lmcccm-p-btn-secondary-active-text: #{tokens.$action-primary-hover};
  --lmcccm-p-btn-secondary-active-border: #{tokens.$background-basic};
  --lmcccm-toggle-bg-on: #{tokens.$action-link-primary-active};
  --lmcccm-toggle-bg-off: #{tokens.$action-link-secondary-default};
}