@use 'sass:map';
@use 'sass:meta';
@use '../@tokens' as tokens;
@use '../settings/utilities' as utilities-settings;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;
@use '@lmc-eu/spirit-web/scss/tools/utilities' as utilities;

@each $breakpoint-name, $breakpoint-value in tokens.$breakpoints {
  @include breakpoint.up($breakpoint-value) {
    $infix: if($breakpoint-value == 0, '', '-#{$breakpoint-name}');

    @each $key, $utility in utilities-settings.$utilities {
      @if meta.type-of($utility) == 'map' and (map.get($utility, responsive) == true or $infix == '') {
        @include utilities.generate($utility, $infix);
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.text-decoration-underline {
  text-decoration: underline;
}

.blurred {
  opacity: 0.5;
  filter: blur(4px);
  user-select: none;
  pointer-events: none;
}