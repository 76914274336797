// common colors
$amethyst-600: #b26ecd;
$blue-gray-300: #90a2a7;
$amaranth-50: #fcf1f4;
$amaranth-500: #ea375f;
$amaranth-600: #b82b4b;
$amaranth-700: #851f36;
$givry-300: #ffd8a7;
$givry-400: #e6c296; // When footer is merged into master, set these colors to footer links respectively.
$givry-500: #ccad85;
$golden-sand-50: #f8f2e4;
$golden-sand-200: #f1d07c;
$golden-sand-300: #dfbb5f;
$golden-sand-400: #c29132;
$gray-50: #f7f7f7;
$gray-100: #f2f2f2;
$gray-300: #e9e9e9;
$gray-400: #dbdbdb;
$gray-500: #d4d4d4;
$gray-600: #c4c4c4;
$gray-700: #737373;
$gray-900: #343434;
$gray-1000: #262626;
$golden-gray-50: #f6f4ef;
$malibu-300: #61b3ff;
$neptune-500: #7dbfa7;
$picton-blue-600: #3f96e0;
$picton-blue-700: #3174ad;
$picton-blue-800: #22527a;
$white: #fff;
$sushi-50: #f6ffed;
$sushi-500: #429a4b;
$sushi-600: #1d7435;
$sushi-700: #175835;

// brand colors
$brand-primary: $amethyst-600;
$brand-secondary: $givry-300;
$brand-tertiary: $gray-900;

// text colors
$text-primary-default: $gray-900;
$text-primary-disabled: $gray-600;
$text-primary-inverted-default: $white;
$text-primary-inverted-disabled: $gray-700;

$text-secondary-default: $blue-gray-300;
$text-secondary-disabled: $gray-600;
$text-secondary-inverted-default: $gray-600;
$text-secondary-inverted-disabled: $gray-700;

// actions colors
$action-primary-default: $picton-blue-600;
$action-primary-hover: $picton-blue-700;
$action-primary-active: $picton-blue-800;
$action-primary-disabled: $gray-300;

$action-secondary-default: $gray-100;
$action-secondary-hover: $gray-400;
$action-secondary-active: $gray-500;
$action-secondary-disabled: $gray-100;

$action-selected-default: $picton-blue-600;
$action-selected-hover: $picton-blue-700;
$action-selected-active: $picton-blue-800;
$action-selected-disabled: $gray-300;

$action-unselected-default: $gray-300;
$action-unselected-hover: $gray-400;
$action-unselected-active: $gray-500;
$action-unselected-disabled: $gray-300;

$action-link-primary-default: $action-primary-default;
$action-link-primary-hover: $action-primary-hover;
$action-link-primary-active: $action-primary-active;
$action-link-primary-disabled: $action-primary-disabled;
$action-link-primary-visited: $neptune-500;

$action-link-secondary-default: $gray-900;
$action-link-secondary-hover: $picton-blue-600;
$action-link-secondary-active: $picton-blue-800;
$action-link-secondary-disabled: $gray-500;
$action-link-secondary-visited: $neptune-500;

// background colors
$background-basic: $white;
$background-cover: $gray-50;
$background-inverted: $gray-900;
$background-warm: $golden-gray-50;

// border colors
$border-primary-default: $gray-300;
$border-primary-hover: $gray-400;
$border-primary-active: $gray-500;
$border-primary-disabled: $gray-100;

// emotion colors
$emotion-danger-default: $amaranth-500;
$emotion-danger-hover: $amaranth-600;
$emotion-danger-active: $amaranth-700;
$emotion-danger-disabled: $gray-500;
$emotion-danger-background: $amaranth-50;

$emotion-warning-default: $golden-sand-200;
$emotion-warning-hover: $golden-sand-300;
$emotion-warning-active: $golden-sand-400;
$emotion-warning-disabled: $gray-500;
$emotion-warning-background: $golden-sand-50;

$emotion-success-default: $sushi-500;
$emotion-success-hover: $sushi-600;
$emotion-success-active: $sushi-700;
$emotion-success-disabled: $gray-500;
$emotion-success-background: $sushi-50;

$emotion-informative-default: #3588b5;
$emotion-informative-hover: #2c7096;
$emotion-informative-active: #20526e;
$emotion-informative-disabled: $gray-500;
$emotion-informative-background: #ebf3f8;

// input colors
$focus-default: $malibu-300;
