@use 'sass:map';
@use '../@tokens' as tokens;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.TextArea {
  width: 100%;
}

.TextArea__input {
  font-size: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
  color: map.get(tokens.$text-colors, 'primary-default');
  border: 1px solid tokens.$border-primary-active;
  resize: none;
}

.TextArea__helperText {
  padding-top: tokens.$space-300;
  font-weight: map.get(tokens.$body-small-text-regular, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
  color: map.get(tokens.$text-colors, 'secondary-default');
}