/**
 * CSS Grid items have an initial size of min-width: auto and min-height: auto.
 *
 * To prevent from growing cols with set min-width to zero.
 * For more info please see https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
 *
 * This selector may be refactored as .Grid--fixedColumns while `className` or `unsafeClassName` prop
 * is implemented to Spirit design system Grid component.
 */

.Grid__column {
  min-width: 0;
}
