@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;
@use '../../../styles/tools' as tools;
@use "@lmc-eu/spirit-web/scss/tools/breakpoint" as breakpoint;
@use "sass:map";

$modal-padding-inline-mobile: tokens.$space-700;

.ModalOverlay {
  position: fixed;
  z-index: tokens.$zindex-modal;
  /* stylelint-disable-next-line color-function-notation */
  background: rgba(colors.$gray-900, 0.6);
  animation: fadeIn 0.15s;
  inset: 0;
}

.Modal {
  @include tools.card ();

  position: absolute;
  top: 50%;
  left: 50%;
  width: 98vw;
  max-height: 95vh;
  padding: tokens.$space-600 $modal-padding-inline-mobile tokens.$space-1000;
  overflow: auto;
  border-radius: tokens.$radius-200;
  background: tokens.$background-basic;
  outline: none;
  box-shadow: tokens.$shadow-300;
  transform: translate(-50%, -50%);

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    width: 60vw;
    padding: tokens.$space-600 tokens.$space-800 tokens.$space-1000;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    width: 40vw;
  }
}

.Modal--withButtons {
  padding: tokens.$space-600 $modal-padding-inline-mobile tokens.$space-800;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    width: 60vw;
    padding: tokens.$space-600 tokens.$space-800 tokens.$space-800;
  }
}

.Modal__large {
  @include breakpoint.up(tokens.$breakpoint-tablet) {
    width: 90vw;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    max-width: 1200px;
  }
}

.Modal__closeButtonWrapper {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.Modal__closeButtonWrapper > button {
  @include tools.circle-icon-button ();
}

.Modal__closeButtonWrapper > button > svg {
  display: block;
}

.Modal__title {
  margin-bottom: tokens.$space-700;
  font-size: map.get(tokens.$heading-small-text, 'mobile', 'font-size');
  text-align: left;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    margin-bottom: tokens.$space-400;
  }
}

.Modal__body--open {
  overflow: hidden;
}