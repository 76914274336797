@use '../@tokens' as tokens;

/* stylelint-disable selector-max-id, selector-max-specificity */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: tokens.$zindex-page-load-progress;
  width: 100%;
  height: tokens.$space-300;
  background-image: tokens.$brand-gradient-reverse;
}

#nprogress .peg {
  position: absolute;
  right: 0;
  display: block;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px tokens.$brand-primary, 0 0 5px tokens.$brand-primary;
  opacity: 1.0;
  transform: rotate(3deg) translate(0, -4px);
}
/* stylelint-enable */