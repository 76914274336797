@use 'sass:map';
@use "../@tokens" as tokens;

.Tag {
  text-align: left;
}

.Tag--xsmall {
  font-size: map.get(tokens.$body-xsmall-text-regular, 'mobile', 'font-size');
}

.Tag--small {
  padding: tokens.$space-300 tokens.$space-500;
}

.Tag--subtle.Tag--neutral {
  border: tokens.$border-width-100 tokens.$border-style-100 tokens.$border-primary-default;
  background-color: transparent;
}

.Tag--round {
  padding: tokens.$space-300 - tokens.$border-width-100 tokens.$space-600 - tokens.$border-width-100;
  border-radius: 1em;
}
