@use '../@tokens' as tokens;
@use 'sass:map';

// Force hardcoded CSS property values from Spirit Design System

.Checkbox__text {
  display: flex;
  align-items: center;
  margin-left: tokens.$space-400;
}

.Checkbox__label {
  font-size: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
}
