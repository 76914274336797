.Toast {
  z-index: 10; // We have to set larger z-index because our searchbar in navigation was overlapping the toast
}

.ToastBar__box {
  max-width: 600px;
}

.ToastBar a {
  text-decoration: underline;
  color: var(--toast-bar-color);
}